/**
 * Type: ページ
 * What: 免税
 */
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import Image from '../util/Image';

const pageTitle = '免税手続き開始のお知らせ';
const pageDescription = '【12/14～】免税手続き開始のお知らせ';
const pageSlug = 'tax-free';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline mb0">
          <span>【12/14～】<br className='sp' />免税手続き開始のお知らせ</span>
        </h3>
        <div className="inner-slim trans">
          <div className="col-image">
            <figure className="image">
              <Image filename="img-tax-free.jpg" />
            </figure>
          </div>
          <p className='text is-3 is-3-sp'>BOSS E・ZO FUKUOKAでは、12月14日（木）より、海外から来日されたお客様の免税手続きを開始いたします。<br className='sp' />免税についてのお問い合わせは3F informationまでお越しください。</p>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="inner trans">
          <h4 className='title is-3 headline pc-mb60 sp-mb30  mt-60'>
            <span>免税手続き概要</span>
          </h4>
          <p className='text is-3 is-4-sp pc-mb30 sp-mb20'>対象店舗でご購入いただいた商品とレシート、パスポートを3F information（免税カウンター）までお持ちください。</p>
          <div className="slime-table">
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>開始日</th>
                    <td className="is-size-6">
                      12月14日（木）～
                    </td>
                  </tr>
                  <tr>
                    <th>営業時間</th>
                    <td className="is-size-6">
                      <p className='mb10'>平日　11:00～20:30</p>
                      <p>土日祝　10:00～20:30</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      場所
                    </th>
                    <td className="is-size-6">3F　information（免税カウンター）</td>
                  </tr>
                  <tr>
                    <th>
                      対象店舗
                    </th>
                    <td className="is-size-6">
                      <p className='mb10'>7F　Sanrio characters Dream!ing Park</p>
                      <p className='mb10'>5F　チームラボフォレスト 福岡 – SBI証券（お絵描きファクトリー）</p>
                      <p className='mb10'>4F　王貞治ベースボールミュージアム Supported by DREAM ORDER</p>
                      <p>3F　MLB café FUKUOKA（物販）</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      免税対象金額
                    </th>
                    <td className="is-size-6">
                      <p className='mb10'>＜一般物品＞</p>
                      <p className='mb30'>税込5,500円以上が免税対象</p>
                      <p className='mb10'>＜食品＞</p>
                      <p>税込5,400円以上55万円以下が免税対象</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      注意事項
                    </th>
                    <td className="is-size-6">
                      <ul className='list-base'>
                        <li className='mb10'>免税となるのは、ご来場日当日に購入いただいたもののみです。後日の対応はできかねます。</li>
                        <li className='mb10'>ご本人のパスポートがない場合は免税の手続きができません。</li>
                        <li className="attention">日本円での支払い、クレジットカード、アプリ決済すべての支払いが対象ですが、返金は日本円のみとなります。</li>
                        <li className='mb10'>対象店舗のレシートは合算が可能です。</li>
                        <li className='mb10'>すべてのお買い物が終了してから手続きを行ってください。免税の手続きを終えた後に、税込5,500円未満（食品は税込5,400円未満）のレシートをご提示いただいても、追加でのお手続きはできかねます。</li>
                        <li className='mb10'>お菓子や化粧品、文房具などの「消耗品」と、キーホルダーや衣類などの「一般物品」は合算が可能です。但し、その場合には「消耗品」も「一般物品」も、日本国内で使用ができないように包装させていただきます。</li>
                        <li className='mb10'>お菓子や化粧品、文房具などの「消耗品」は日本を出国するまでご使用いただけません。日本国内で使用されるものは免税できません。</li>
                        <li className='mb10'>自動化ゲートを通って入国した場合など、スタンプがない場合は入国日を照明するものがないため、免税の手続きはできかねます。</li>
                        <li className='mb10'>免税手続き後の返品・交換はできません。</li>
                        <li className='mb10'>包装済みの商品は、出国するまで開けないでください。日本国内で開けてしまった場合、出国時の税関で消費税相当額をお支払いいただきます。</li>
                        <li className='mb10'>お客様への還付金額は税抜価格より算出いたします。手続き手数料として2%をいただきます。</li>
                        <li>出国時は空港や港の税関で、パスポート等と物品をご提示ください。</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      お問い合わせ先
                    </th>
                    <td className="is-size-6">
                      <p className='mb10 fwb'>BOSS E・ZO FUKUOKAに関するお問い合わせ</p>
                      <p className='mb10'>以下のお問合せフォームまでお問合せください。</p>
                      <p><a href="/inquiry/form/" className='tx-link'>https://e-zofukuoka.com/inquiry/form/</a></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
